import React, { useState } from "react"
import {
  Disclosure,
  Transition,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"
import SEO from "../components/seo"

const WorkPage = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <div className="mx-auto">
        <main className="flex flex-col justify-center items-center">
          <SEO title="Learn" />
          {/* Header */}
          <div className="relative lg:max-w-[1400px] w-full flex flex-col items-center justify-center rounded-b-[15px] pt-[32px] lg:pt-[48px] pb-[96px] px-[16px] lg:px-0 gap-[36px] lg:gap-[96px] relative overflow-hidden">
            <div
              style={{ maxWidth: 750 }}
              className="lg:absolute lg:block hidden top-0 right-0 z-0"
            >
              <StaticImage
                src="../images/rec_head_top.png"
                alt="bg rectangle"
                className=""
              />
            </div>
            <div
              style={{ maxWidth: 750 }}
              className="lg:absolute lg:block hidden bottom-0 left-0 z-0"
            >
              <StaticImage
                src="../images/rec_head_bottom.png"
                alt="bg rectangle"
                className=""
              />
            </div>

            <div className="w-full flex justify-between items-center lg:px-[48px] z-[1]">
              <div className="flex justify-center lg:justify-start">
                <StaticImage
                  src="../images/bb_logo2.png"
                  alt="BBEnergy.AI logo"
                  style={{ maxWidth: 220 }}
                />
              </div>

              {/* Burger Icon for Mobile */}
              <div className="lg:hidden relative z-[100]">
                <button
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="focus:outline-none"
                >
                  <svg
                    className="w-8 h-8 text-[#090A0B]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={
                        menuOpen
                          ? "M6 18L18 6M6 6l12 12"
                          : "M4 6h16M4 12h16M4 18h16"
                      }
                    ></path>
                  </svg>
                </button>

                {/* Menu for Mobile */}
                <div
                  className={`${
                    menuOpen ? "flex" : "hidden"
                  } absolute right-0 top-[60px] flex-col bg-white p-4 shadow-lg rounded-lg gap-4 z-1`}
                >
                  <ScrollLink
                    to="product"
                    smooth={true}
                    duration={500}
                    className="text-[18px] font-medium text-[#090A0B] cursor-pointer w-[150px]"
                  >
                    How It Works
                  </ScrollLink>
                  <ScrollLink
                    to="faqs"
                    smooth={true}
                    duration={500}
                    className="text-[18px] font-medium text-[#090A0B] cursor-pointer w-[150px]"
                  >
                    FAQs
                  </ScrollLink>
                  <Link
                    to="/work"
                    className="text-[18px] font-medium text-[#090A0B] cursor-pointer w-[150px]"
                  >
                    For Work
                  </Link>
                </div>
              </div>

              {/* Menu Items for Desktop */}
              <div className="hidden lg:flex gap-[64px]">
                <ScrollLink
                  to="product"
                  smooth={true}
                  duration={500}
                  className="text-[20px] font-medium text-[#090A0B] cursor-pointer"
                >
                  How It Works
                </ScrollLink>
                <ScrollLink
                  to="faqs"
                  smooth={true}
                  duration={500}
                  className="text-[20px] font-medium text-[#090A0B] cursor-pointer"
                >
                  FAQs
                </ScrollLink>
                <Link
                  to="/work"
                  className="text-[20px] font-medium text-[#090A0B] cursor-pointer"
                >
                  For Work
                </Link>
              </div>
            </div>

            <div className="w-full flex gap-[96px] justify-between items-center flex-col lg:flex-row lg:px-[48px] lg:z-[1]">
              <div className="flex flex-col items-start gap-[36px] w-full max-w-[568px]">
                <h1 className="text-[#141414] text-[42px] lg:text-[50px] font-semibold leading-tight lg:z-[1]">
                  Upskill yourself on any topic for 5 minutes a day
                </h1>
                <p className="text-[#141414] text-[22px] font-medium leading-[150%] lg:z-[1]">
                  The Big Brain Energy app helps you learn new skills for work
                  and life in short, AI-curated and personalized lessons.
                </p>
                <div className="flex flex-col gap-[10px]">
                  <div className="flex items-center gap-[32px]">
                    <div style={{ maxWidth: `190px` }}>
                      <Link to="/learn-iphone">
                        <StaticImage
                          src="../images/app_store.png"
                          alt="App Store"
                        />
                      </Link>
                    </div>
                    <div style={{ maxWidth: `190px` }}>
                      <Link to="/learn-android">
                        <StaticImage
                          src="../images/play_store.png"
                          alt="Play Store"
                        />
                      </Link>
                    </div>
                  </div>
                  <p className="text-[#475467] text-[16px] italic">
                    Download the app, it's free
                  </p>
                </div>
              </div>
              <div className="max-w-[300px] lg:mr-[50px]">
                <StaticImage src="../images/lesson-screen.png" alt="mockup" />
              </div>
            </div>
          </div>

          {/* users like */}
          <div className="w-full flex flex-col items-center justify-center rounded-[15px] pt-[48px] pb-[0px] lg:py-[48px] px-[16px] lg:px-0 gap-[48px] mb-[64px] bg-[rgba(226,234,245,0.2)] lg:max-w-[1400px]">
            <div className="flex flex-col items-center justify-center gap-[16px]">
              <div
                className="w-fit text-[18px] font-bold p-[12px] rounded-[4px] text-[#892FE0]
                                        pill-bg pill-shadow"
              >
                What you'll learn
              </div>
              <h2 className="text-[#101828] text-[36px] font-semibold leading-[130%] tracking-[-0.72px]">
                Our users are learning things like...
              </h2>
            </div>
            <div className="flex flex-col-reverse lg:flex-row items-center gap-[48px] lg:gap-[96px]">
              <div
                class="relative lg:static lg:left-0"
                style={{ maxWidth: `500px`, left: "-10px" }}
              >
                <StaticImage
                  src="../images/girl.png"
                  alt="Leonardo.AI mockup"
                />
              </div>
              <div>
                <p className="text-[20px] text-[#141414] pb-[16px]">
                  "Investing 101s for a busy professional"
                </p>
                <p className="text-[20px] text-[#141414] pb-[16px]">
                  "United Stated Presidential Election system"
                </p>
                <p className="text-[20px] text-[#141414] pb-[16px]">
                  "Digital marketing concepts for a sales person"
                </p>
                <p className="text-[20px] text-[#141414] pb-[16px]">
                  "Ancient Egyptian history"
                </p>
                <p className="text-[20px] text-[#141414] pb-[32px]">
                  "How does AI actually work?"
                </p>
                <p className="text-[20px] text-[#141414] font-semibold">
                  But you don't have to, you should do you 😉
                </p>
              </div>
            </div>
          </div>

          {/* how it works */}
          <div
            id="product"
            className="w-full flex flex-col items-center justify-center py-[96px] px-[16px] gap-[64px] bg-white"
          >
            <div className="flex flex-col items-center justify-center gap-[24px]">
              <div className="w-fit text-[16px] font-bold p-[12px] rounded-[4px] text-[#892FE0] pill-bg pill-shadow">
                How Big Brain Energy Works
              </div>
              <h2 className="text-[#101828] text-4xl font-semibold leading-tight text-center">
                The Duolingo Learning Experience For Any Topic
              </h2>
            </div>

            <div className="flex flex-col gap-8 w-full max-w-[1400px]">
              <div className="px-[16px] py-[24px] lg:p-[32px] bg-[#F9FAFB] rounded-xl w-full">
                <Disclosure defaultOpen>
                  {({ open }) => (
                    <div className="flex flex-col lg:flex-row justify-between">
                      <div>
                        <DisclosureButton className="flex gap-2 max-w-[500px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            className={`transform transition-transform duration-200 ${
                              open ? "rotate-0" : "rotate-180"
                            }`}
                          >
                            <path
                              d="M12.0446 9.15282L18.9698 16.0789C19.0394 16.1487 19.1222 16.204 19.2132 16.2417C19.3043 16.2795 19.4019 16.2989 19.5004 16.2989C19.599 16.2989 19.6966 16.2795 19.7876 16.2417C19.8787 16.204 19.9614 16.1487 20.031 16.0789L12.0446 9.15282ZM12.0446 9.15282L12.0004 9.10863L11.9562 9.15282M12.0446 9.15282H11.9562M11.9562 9.15282L5.03104 16.0789C4.89031 16.2197 4.69944 16.2987 4.50042 16.2987C4.30139 16.2987 4.11052 16.2197 3.96979 16.0789C3.82906 15.9382 3.75 15.7473 3.75 15.5483C3.75 15.3493 3.82906 15.1584 3.96979 15.0177L11.9562 9.15282ZM19.014 16.0348L19.014 16.0348L12.0446 9.06444L12.0004 9.02024L11.9562 9.06444L4.98684 16.0348C4.85784 16.1638 4.68286 16.2362 4.50042 16.2362C4.31797 16.2362 4.143 16.1638 4.01399 16.0348C3.88498 15.9057 3.8125 15.7308 3.8125 15.5483C3.8125 15.3659 3.88498 15.1909 4.01399 15.0619L11.514 7.56189L11.514 7.56186C11.5779 7.49794 11.6537 7.44723 11.7371 7.41264C11.8206 7.37804 11.9101 7.36023 12.0004 7.36023C12.0908 7.36023 12.1802 7.37804 12.2637 7.41264C12.3471 7.44723 12.423 7.49794 12.4868 7.56186L12.4868 7.56189L19.9869 15.0619L19.9869 15.0619C20.0508 15.1258 20.1015 15.2016 20.1361 15.285C20.1707 15.3685 20.1885 15.458 20.1885 15.5483C20.1885 15.6387 20.1707 15.7281 20.1361 15.8116C20.1015 15.8951 20.0508 15.9709 19.9869 16.0347L19.9868 16.0348C19.923 16.0987 19.8471 16.1494 19.7637 16.184C19.6802 16.2186 19.5908 16.2364 19.5004 16.2364C19.4101 16.2364 19.3206 16.2186 19.2371 16.184C19.1537 16.1494 19.0779 16.0987 19.014 16.0348Z"
                              fill="#090A0B"
                              stroke="#090A0B"
                              stroke-width="0.125"
                            />
                          </svg>
                          <h2 className="text-xl lg:text-2xl text-left font-semibold">
                            Create Your Unique Learning Plan
                          </h2>
                        </DisclosureButton>
                        <Transition
                          enter="transition duration-200 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <DisclosurePanel className="mt-[30px] flex gap-2">
                            <div className="flex flex-col gap-[16px] text-[18px] max-w-[500px]">
                              <p>
                                Talk to our AI to decide what you'll learn,
                                create a plan and tailor it to your needs.
                              </p>
                              <p>
                                Select from dozens of pre-made starters or start
                                completely from scratch.
                              </p>
                            </div>
                          </DisclosurePanel>
                        </Transition>
                      </div>

                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel>
                          <div style={{ maxWidth: `630px` }}>
                            <StaticImage
                              src="../images/create.png"
                              alt="Section image"
                            />
                          </div>
                        </DisclosurePanel>
                      </Transition>
                    </div>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-[#F9FAFB] rounded-xl w-full">
                <Disclosure>
                  {({ open }) => (
                    <div className="flex flex-col lg:flex-row justify-between">
                      <div>
                        <DisclosureButton className="flex gap-2 max-w-[500px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            className={`transform transition-transform duration-200 ${
                              open ? "rotate-0" : "rotate-180"
                            }`}
                          >
                            <path
                              d="M12.0446 9.15282L18.9698 16.0789C19.0394 16.1487 19.1222 16.204 19.2132 16.2417C19.3043 16.2795 19.4019 16.2989 19.5004 16.2989C19.599 16.2989 19.6966 16.2795 19.7876 16.2417C19.8787 16.204 19.9614 16.1487 20.031 16.0789L12.0446 9.15282ZM12.0446 9.15282L12.0004 9.10863L11.9562 9.15282M12.0446 9.15282H11.9562M11.9562 9.15282L5.03104 16.0789C4.89031 16.2197 4.69944 16.2987 4.50042 16.2987C4.30139 16.2987 4.11052 16.2197 3.96979 16.0789C3.82906 15.9382 3.75 15.7473 3.75 15.5483C3.75 15.3493 3.82906 15.1584 3.96979 15.0177L11.9562 9.15282ZM19.014 16.0348L19.014 16.0348L12.0446 9.06444L12.0004 9.02024L11.9562 9.06444L4.98684 16.0348C4.85784 16.1638 4.68286 16.2362 4.50042 16.2362C4.31797 16.2362 4.143 16.1638 4.01399 16.0348C3.88498 15.9057 3.8125 15.7308 3.8125 15.5483C3.8125 15.3659 3.88498 15.1909 4.01399 15.0619L11.514 7.56189L11.514 7.56186C11.5779 7.49794 11.6537 7.44723 11.7371 7.41264C11.8206 7.37804 11.9101 7.36023 12.0004 7.36023C12.0908 7.36023 12.1802 7.37804 12.2637 7.41264C12.3471 7.44723 12.423 7.49794 12.4868 7.56186L12.4868 7.56189L19.9869 15.0619L19.9869 15.0619C20.0508 15.1258 20.1015 15.2016 20.1361 15.285C20.1707 15.3685 20.1885 15.458 20.1885 15.5483C20.1885 15.6387 20.1707 15.7281 20.1361 15.8116C20.1015 15.8951 20.0508 15.9709 19.9869 16.0347L19.9868 16.0348C19.923 16.0987 19.8471 16.1494 19.7637 16.184C19.6802 16.2186 19.5908 16.2364 19.5004 16.2364C19.4101 16.2364 19.3206 16.2186 19.2371 16.184C19.1537 16.1494 19.0779 16.0987 19.014 16.0348Z"
                              fill="#090A0B"
                              stroke="#090A0B"
                              stroke-width="0.125"
                            />
                          </svg>
                          <h2 className="text-xl lg:text-2xl text-left font-semibold">
                            Complete short lessons
                          </h2>
                        </DisclosureButton>
                        <Transition
                          enter="transition duration-200 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <DisclosurePanel className="mt-[30px] flex gap-2">
                            <div className="flex flex-col gap-[16px] text-[18px] max-w-[500px]">
                              <p>
                                Our AI takes the learning plan and generates
                                short and engaging lessons.
                              </p>
                              <p>
                                Lessons take no more than 5 minutes to complete,
                                making it easy to fit learning into your daily
                                routine.
                              </p>
                            </div>
                          </DisclosurePanel>
                        </Transition>
                      </div>

                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel>
                          <div style={{ maxWidth: `630px` }}>
                            <StaticImage
                              src="../images/complete.png"
                              alt="Section image"
                            />
                          </div>
                        </DisclosurePanel>
                      </Transition>
                    </div>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-[#F9FAFB] rounded-xl w-full">
                <Disclosure>
                  {({ open }) => (
                    <div className="flex flex-col lg:flex-row justify-between">
                      <div>
                        <DisclosureButton className="flex gap-2 max-w-[500px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            className={`transform transition-transform duration-200 ${
                              open ? "rotate-0" : "rotate-180"
                            }`}
                          >
                            <path
                              d="M12.0446 9.15282L18.9698 16.0789C19.0394 16.1487 19.1222 16.204 19.2132 16.2417C19.3043 16.2795 19.4019 16.2989 19.5004 16.2989C19.599 16.2989 19.6966 16.2795 19.7876 16.2417C19.8787 16.204 19.9614 16.1487 20.031 16.0789L12.0446 9.15282ZM12.0446 9.15282L12.0004 9.10863L11.9562 9.15282M12.0446 9.15282H11.9562M11.9562 9.15282L5.03104 16.0789C4.89031 16.2197 4.69944 16.2987 4.50042 16.2987C4.30139 16.2987 4.11052 16.2197 3.96979 16.0789C3.82906 15.9382 3.75 15.7473 3.75 15.5483C3.75 15.3493 3.82906 15.1584 3.96979 15.0177L11.9562 9.15282ZM19.014 16.0348L19.014 16.0348L12.0446 9.06444L12.0004 9.02024L11.9562 9.06444L4.98684 16.0348C4.85784 16.1638 4.68286 16.2362 4.50042 16.2362C4.31797 16.2362 4.143 16.1638 4.01399 16.0348C3.88498 15.9057 3.8125 15.7308 3.8125 15.5483C3.8125 15.3659 3.88498 15.1909 4.01399 15.0619L11.514 7.56189L11.514 7.56186C11.5779 7.49794 11.6537 7.44723 11.7371 7.41264C11.8206 7.37804 11.9101 7.36023 12.0004 7.36023C12.0908 7.36023 12.1802 7.37804 12.2637 7.41264C12.3471 7.44723 12.423 7.49794 12.4868 7.56186L12.4868 7.56189L19.9869 15.0619L19.9869 15.0619C20.0508 15.1258 20.1015 15.2016 20.1361 15.285C20.1707 15.3685 20.1885 15.458 20.1885 15.5483C20.1885 15.6387 20.1707 15.7281 20.1361 15.8116C20.1015 15.8951 20.0508 15.9709 19.9869 16.0347L19.9868 16.0348C19.923 16.0987 19.8471 16.1494 19.7637 16.184C19.6802 16.2186 19.5908 16.2364 19.5004 16.2364C19.4101 16.2364 19.3206 16.2186 19.2371 16.184C19.1537 16.1494 19.0779 16.0987 19.014 16.0348Z"
                              fill="#090A0B"
                              stroke="#090A0B"
                              stroke-width="0.125"
                            />
                          </svg>
                          <h2 className="text-xl lg:text-2xl text-left font-semibold">
                            Save your progress
                          </h2>
                        </DisclosureButton>
                        <Transition
                          enter="transition duration-200 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <DisclosurePanel className="mt-[30px] flex gap-2">
                            <div className="flex flex-col gap-[16px] text-[18px] max-w-[500px]">
                              <p>
                                Once you complete a plan, you receive a summary
                                of what you’ve learnt, recommendations for
                                further reading and suggestions for what to
                                learn next.
                              </p>
                              <p>
                                Content from all completed lessons is always
                                available to refer back to in the Big Brain
                                Energy app.
                              </p>
                            </div>
                          </DisclosurePanel>
                        </Transition>
                      </div>

                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel>
                          <div style={{ maxWidth: `630px` }}>
                            <StaticImage
                              src="../images/save.png"
                              alt="Section image"
                            />
                          </div>
                        </DisclosurePanel>
                      </Transition>
                    </div>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-[#F9FAFB] rounded-xl w-full">
                <Disclosure>
                  {({ open }) => (
                    <div className="flex flex-col lg:flex-row justify-between">
                      <div>
                        <DisclosureButton className="flex gap-2 max-w-[500px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            className={`transform transition-transform duration-200 ${
                              open ? "rotate-0" : "rotate-180"
                            }`}
                          >
                            <path
                              d="M12.0446 9.15282L18.9698 16.0789C19.0394 16.1487 19.1222 16.204 19.2132 16.2417C19.3043 16.2795 19.4019 16.2989 19.5004 16.2989C19.599 16.2989 19.6966 16.2795 19.7876 16.2417C19.8787 16.204 19.9614 16.1487 20.031 16.0789L12.0446 9.15282ZM12.0446 9.15282L12.0004 9.10863L11.9562 9.15282M12.0446 9.15282H11.9562M11.9562 9.15282L5.03104 16.0789C4.89031 16.2197 4.69944 16.2987 4.50042 16.2987C4.30139 16.2987 4.11052 16.2197 3.96979 16.0789C3.82906 15.9382 3.75 15.7473 3.75 15.5483C3.75 15.3493 3.82906 15.1584 3.96979 15.0177L11.9562 9.15282ZM19.014 16.0348L19.014 16.0348L12.0446 9.06444L12.0004 9.02024L11.9562 9.06444L4.98684 16.0348C4.85784 16.1638 4.68286 16.2362 4.50042 16.2362C4.31797 16.2362 4.143 16.1638 4.01399 16.0348C3.88498 15.9057 3.8125 15.7308 3.8125 15.5483C3.8125 15.3659 3.88498 15.1909 4.01399 15.0619L11.514 7.56189L11.514 7.56186C11.5779 7.49794 11.6537 7.44723 11.7371 7.41264C11.8206 7.37804 11.9101 7.36023 12.0004 7.36023C12.0908 7.36023 12.1802 7.37804 12.2637 7.41264C12.3471 7.44723 12.423 7.49794 12.4868 7.56186L12.4868 7.56189L19.9869 15.0619L19.9869 15.0619C20.0508 15.1258 20.1015 15.2016 20.1361 15.285C20.1707 15.3685 20.1885 15.458 20.1885 15.5483C20.1885 15.6387 20.1707 15.7281 20.1361 15.8116C20.1015 15.8951 20.0508 15.9709 19.9869 16.0347L19.9868 16.0348C19.923 16.0987 19.8471 16.1494 19.7637 16.184C19.6802 16.2186 19.5908 16.2364 19.5004 16.2364C19.4101 16.2364 19.3206 16.2186 19.2371 16.184C19.1537 16.1494 19.0779 16.0987 19.014 16.0348Z"
                              fill="#090A0B"
                              stroke="#090A0B"
                              stroke-width="0.125"
                            />
                          </svg>
                          <h2 className="text-xl lg:text-2xl text-left font-semibold">
                            Keep on learning
                          </h2>
                        </DisclosureButton>
                        <Transition
                          enter="transition duration-200 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <DisclosurePanel className="mt-[30px] flex gap-2">
                            <div className="flex flex-col gap-[16px] text-[18px] max-w-[500px]">
                              <p>
                                Keep things fresh by learning new things all the
                                time.
                              </p>
                              <p>
                                Depending on the topic, a single learning plan
                                might take 1-3 months to complete. Switch to
                                anything you want after.
                              </p>
                            </div>
                          </DisclosurePanel>
                        </Transition>
                      </div>

                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel>
                          <div style={{ maxWidth: `630px` }}>
                            <StaticImage
                              src="../images/stay.png"
                              alt="Section image"
                            />
                          </div>
                        </DisclosurePanel>
                      </Transition>
                    </div>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* faqs section */}
          <div
            id="faqs"
            className="w-full flex flex-col items-center justify-center py-[96px] px-[16px] lg:px-[96px] gap-[64px] bg-[#F9FAFB]"
          >
            <div className="flex flex-col items-center justify-center gap-[24px]">
              <div className="w-fit text-[16px] font-bold p-[12px] rounded-[4px] text-[#892FE0] pill-bg pill-shadow">
                FAQs
              </div>
              <h2 className="text-[#101828] text-4xl font-semibold leading-tight text-center">
                Some Questions You Might Have
              </h2>
            </div>

            <div className="flex flex-col items-center justify-center gap-[16px] w-full">
              <div className="px-[16px] py-[24px] lg:p-[32px] bg-white rounded-xl border border-[#F0F0F0] w-full max-w-[748px]">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="w-full flex justify-between items-center">
                        <h2 className="text-lg text-left font-semibold">
                          What is Big Brain Energy?
                        </h2>
                        {!open ? (
                          <StaticImage
                            src="../images/plus.png"
                            alt="Plus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        ) : (
                          <StaticImage
                            src="../images/minus.png"
                            alt="Minus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        )}
                      </DisclosureButton>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel className="mt-2 flex flex-col gap-2 text-[#71717A] text-[18px]">
                          <p>
                            Big Brain Energy is an AI learning app offering fun,
                            bite-sized daily lessons on different topics, using
                            AI to generate unique learning experiences for every
                            user.
                          </p>
                        </DisclosurePanel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-white rounded-xl border border-[#F0F0F0] w-full max-w-[748px]">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="w-full flex justify-between items-center">
                        <h2 className="text-lg text-left font-semibold">
                          What can I learn on Big Brain Energy?
                        </h2>
                        {!open ? (
                          <StaticImage
                            src="../images/plus.png"
                            alt="Plus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        ) : (
                          <StaticImage
                            src="../images/minus.png"
                            alt="Minus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        )}
                      </DisclosureButton>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel className="mt-2 flex flex-col gap-2 text-[#71717A] text-[18px]">
                          <p>
                            Anything you want! We provide ideas and suggestions
                            to get you started across history, work, science,
                            and more.
                          </p>
                        </DisclosurePanel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-white rounded-xl border border-[#F0F0F0] w-full max-w-[748px]">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="w-full flex justify-between items-center">
                        <h2 className="text-lg text-left font-semibold">
                          Where does the educational content come from?
                        </h2>
                        {!open ? (
                          <StaticImage
                            src="../images/plus.png"
                            alt="Plus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        ) : (
                          <StaticImage
                            src="../images/minus.png"
                            alt="Minus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        )}
                      </DisclosureButton>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel className="mt-2 flex flex-col gap-2 text-[#71717A] text-[18px]">
                          <p>
                            We use advanced AI models to curate learning content
                            on Big Brain Energy.
                          </p>
                          <p>
                            We also apply human curation to ensure the quality
                            and relevance of the content, as well as scientific
                            teaching methodologies to ensure the lessons are
                            engaging and effective!
                          </p>
                        </DisclosurePanel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </main>

        <footer>
          <div className="flex justify-center text-[#475467] text-center text-[16px] lg:mt-[12px] mb-[10px] px-[32px] lg:px-0">
            <p>
              Big Brain Energy.AI © {new Date().getFullYear()}. Made with&nbsp;
              <a
                href="https://www.tiktok.com/@yamaliavkaa/video/7157416182616820997"
                target="_blank"
                className="pb-[0px] border-b border-[#141414] inline-flex"
              >
                🍺
              </a>
              &nbsp;and&nbsp;
              <a
                href="https://www.tiktok.com/@gabinlondon/video/7059435150106496262?q=sunset%20primrose%20hill%20romantic&t=1716209988363"
                target="_blank"
                className="pb-[0px] border-b border-[#141414] inline-flex"
              >
                ❤️
              </a>
              &nbsp;in London 🇬🇧
            </p>
          </div>
          <div className="flex justify-center text-[#475467] text-center text-[16px] mb-[32px] px-[32px] lg:px-0">
            <p>
              See our&nbsp;
              <a
                href="/terms"
                target="_blank"
                className="pb-[0px] border-b border-[#141414] inline-flex text-black"
              >
                Terms and Conditions
              </a>
              &nbsp;and&nbsp;
              <a
                href="/privacy"
                target="_blank"
                className="text-black pb-[0px] border-b border-[#141414] inline-flex"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </footer>
      </div>
    </>
  )
}

export default WorkPage
